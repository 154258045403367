import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const MonetizeSite = () => (
  <Layout>
    <SEO
      title="6 Steps to Monetize Your Website"
      description="Everything you need to know to monetize your website. You've done the hard part in figuring out how to get traffic, here's how to turn traffic into money"
    />
    <h1>6 Steps to Monetize Your Website</h1>
    <p>I feel like a lot of the information out there about making money online is very much recycled. If you didn't know, making money online is it's very own niche. By joining certain groups or following certain Youtubers, you are in a funnel of some kind.</p>
    <p>I won't complain about shady practises because as you learn more, you will figure out how to spot them. There are a bunch of different way to monetise; some people are affiliates and others sell their own product or service. All online marketing intersects because the theory is the same. Convince someone to take action and convert to whatever you are offering.</p>
    <p>In this article I am going to go over specific situations. You have a steady flow of traffic going to your site daily through Google and you want to figure out how to monetise it.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/w7NydjJ.jpg"
        alt="campbell data science how to domain name"
        description="Campbell data science 10 things you should know before buying a domain name"
      />
    </div>
    <p>
      Photo by 
      <a
        href="https://unsplash.com/@giorgiotrovato?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Giorgio Trovato
      </a>
       on Unsplash
    </p>

    <p>Here are the some of the things you might not have come across and things you figure out after gaining real experience building websites.</p>
    <h2>Setting Up</h2>
    <p>Some of you may have already done this but if you haven't, a very important step is to {" "}<a
        href="https://search.google.com/search-console/about"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      install Google Search Console
      </a>. Without this, you won't be able to follow this guide. Google Search Console tells you the exact keywords people use to get to your site.</p>
    <p>If you've had search console installed for a while then you are okay to move onto the next step. If you are just installing it now then you need to let the data accumulate so you have something to analyse later on.</p>

    <h2>1. Analysis</h2>
    <p>Go into search console, open your performance report and look at your top most performing pages. Select each one to filter all the other data to that page then go over to the queries tab to see what the top searched keywords are.</p>
    <p>To do this make sure the columns are arranged by click, it should be set that way by default. Then make a list of the top most queries. You might find that they are all similar variations of the same keyword so the people that land on this page are all looking for the same thing. That's the best case scenario for what we are going to do.</p>

    <h2>2. Keyword Analysis</h2>
    <p>Take these keywords and compare them to the content on your page. Do they align and is there good reason the search engine is sending traffic to the page? Now ask yourself what product you can make to support the content. How can this product improve user experience and make a difference in a positive way?</p>

    <h2>3. Product Analysis</h2>
    <p>Then go and look for similar products indoor own niche. If your niche is very narrow, find similar products in other niches. Get a list together and make a board of all the most popular products that are listed on highly ranked sites.</p>

    <h2>4. Create Your Own Product</h2>
    <p>This is the step that makes all the difference to whether you have a successful money generating platform or not. If you are an affiliate and you make some money from your site already then you know that you are able to convert. Why not create a product and create something specific to your exact audience.</p>
    <p>It's easy to think that we need to come up with something unique and special but there is nothing wrong with copying an idea from somewhere that you know is working and adjusting it to suit your needs. It is far harder to sell a brand new idea than something that people already understand.</p>
    <p>The audience trusts you platform already so give them what they are looking for. Obviously, this won't work for every single page because not all of your content with support a relevant digital product.</p>
    <p>If you are struggling for {" "}<a
        href="https://blog.sellfy.com/101-digital-products-you-can-sell-online/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      ideas for digital products
      </a>, there are plenty of lists online. There are also a few places to list and sell your digital products so you don't have to set up your own store initially.</p>

    <h2>5. Test</h2>
    <p>When you have a product that you would like to test, make a series of ads and mix them into your content. One mistake I see is when site owners place one ad on a page. In marketing, the more a user sees an ad, the more likely they are to convert. Aim to have a series of at least three ads on a page with the reason to click getting more attractive each time.</p>
    <p>The first ad is the introduction to the product. The second displays more information about it. The third ad should display some kind of final push like a discount of special offer.</p>
    <p>For each of your product ideas, run this for a few days and see which converts best for the product idea. The benefit to all this is that it is like running an ad campaign but your clicks are free because you are getting them through SEO and the product is matched exactly to your content.</p>

    <h2>6. Scale</h2>
    <p>Through all the testing, you will find a few products that work. Now you can start running ads and reaching out to other content creators to push your product for you. One of the benefits of having a digital product is that a lot of the platforms allow you to ad affiliates. This means that they get a commission every time someone buys through their link.</p>

    <h2>What Next?</h2>
    <p>Monetising your site can be a stressful experience but if you stick with it, the rewards can be amazing. When you look at the potential reach of your site and combine it with the average customer value, it's easy to see how much value you can create. Then you can run your site as a lifestyle business or have an exit and move onto the next project.</p>
    <p>Quick note, a lifestyle business is one that allows you to take a profit while spending enough time on it to enjoy your life outside of the business. Much like the idea of passive income but we all know nothing is truly passive so I don't want to be associated with that term.</p>
    <p>An exit is another term for selling your business. You take a multiple of the profit per month and sell it for that. The valuation is higher when you have more data points on your customers. It's not uncommon for businesses to get millions in valuation that are less than 2 years old.</p>
    <p>I'm sure you know already that the internet is an amazing place. The reach is vast and with a good product-market fit, you can create something that can change your life. If you didn't know already, product market/customer fit is what you find from steps 4 and 5. Creating your product based on what you believe your audience might like and testing it then making changes based on customer feedback.</p>
    <p>Eventually, your product will fit your {" "}<a
        href="https://www.entrepreneur.com/article/75648"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        ideal customer
      </a>{" "} in a near perfect way. This is product-customer fit. At this stage you can focus on marketing because you know that when the next set of customers find your product, you don't have to worry about them liking it or not because the majority of them will. Product-market fit is when you have consistency in your ability to reach new customers.</p>
    <p>Now it becomes a game of diminishing returns, do you focus on making last few changes to the product? Or do you focus on the other things in your business that will make more of an immediate impact?</p>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default MonetizeSite
